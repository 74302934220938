<template>
  <div id="loginId">
    <div class="bg-no-repeat fixed bg-cover inset-0 -z-10 bg-marvel-login-background" />
    <div class="bg-custom-gray-12 text-custom-gray-13 relative rounded border border-solid h-auto mr-auto ml-auto mb-2 login-inner-width mt-24">
      <div class="pt-11 pl-0 pr-0 pb-11 relative border-b solid z-10">
        <h1>
          <img
            :src="require('@/assets/images/logo-v2.png')"
            class="block my-0 mx-auto max-w-xs max-h-12"
            alt="Marvel Entertainment External Tenant logo logo"
          >
        </h1>
      </div>
      <div class="py-5 px-10">
        <base-button
          tag="a"
          href="javascript:void(0)"
          class="p-4 block text-center"
          variant="btn-blue"
          @click="handleLogin()"
        >
          <template #default>
            <span>
              I AGREE
            </span>
          </template>
        </base-button>
      </div>
      <div class="py-5 px-10">
        <a
          class="hover:underline"
          href="https://asgardhelp.mvlhub.com"
          target="_blank"
        >
          Security Guidlines Confirmation Link
        </a>
        <div class="mt-2">
          <a
            class="hover:underline"
            href="https://asgardhelp.mvlhub.com"
            target="_blank"
          >
            Having issues with login click here
          </a>
        </div>
        <p class="mt-6">
          By clicking, I AGREE above, I accept on behalf of myself, and my company that:
        </p>
        <ul class="mt-4">
          <li>(A) <span class="text-red-500"> All assets on this site are extremely confidential </span> and there are severe consequences for mishandling or sharing, including termination and legal action; </li>
          <li>(B) <span class="text-red-500"> I am using my own username and password </span> that were assigned to me by Marvel solely for my use, and I have not obtained this username and password from a co-worker or former employee; </li>
          <li>(C) <span class="text-red-500"> I will not share my username or password,  </span> and I will comply with all security guidelines (Click on - "SECURITY GUIDELINES CONFIRMATION LINK" above) provided by Marvel, Disney or its affiliates; </li>
          <li>(D) <span class="text-red-500"> I will not share or distribute assets to others </span> (e.g., co-workers, users, third parties, etc.); and </li>
          <li>(E) Any approvals by Marvel on this site do not (i) expand the scope of a license agreement, or (ii) constitute approval to advertise or produce any product not specifically included in a license agreement. </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <app-footer /> -->
</template>

<script>
import { defineAsyncComponent } from 'vue';
import okta from '@/lib/okta.js';
// import AppFooter from '@/components/AppFooter.vue';

export default {
    name: 'Login',

    components: {
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        // AppFooter
    },

    setup () {
        const handleLogin = async () => {
            try {
                await okta.loginRedirect();
            } catch (err) {
                console.error(err);
            }
        };

        return {
            handleLogin
        };
    }
};
</script>
<style scoped>
.login-inner-width {
  width: 100%;
  max-width: 740px;
}
</style>
